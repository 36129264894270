import React from "react";
// import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import PageWrapper from "../components/Structure/PageWrapper";
import SearchTest from "../components/SearchTest";
import SEO from "../components/seo";

export default ({ data }) => (
	<Layout>
		<SEO title="Search" keywords={[`dumbo`]} />
		<PageWrapper title={"Search"} bonusContent={<SearchTest />} />
	</Layout>
);
